<template>
  <div class="text-center">
    <span :class="className" class="font-weight-bold">{{ row.statusStr }}</span>
  </div>
</template>
<script>
export default {
  name: 'VerificationStatus',
  props: {
    row: Object,
  },
  computed: {
    className() {
      if (!this.row.is_tollfree)
        return ''
      let className = ''
      if (['in_review', 'pending_review'].includes(this.row.verification_status)) {
        className = ''
      } else if (['twilio_approved'].includes(this.row.verification_status)) {
        className = 'text-primary'
      } else {
        className = 'text-danger'
      }
      return className
    }
  },
};
</script>
